import { useEffect, useState } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Spinner from '../Spinner/Spinner';

const StepTwo = ({ formData, nextStep, previousStep, setPlatformUrl, loading, setLoading }) => {
    const [error, setError] = useState('');
    const [queryParams, setQueryParams] = useState(new URLSearchParams(window.location.search));

    const confirmEmail = async () => {
        setLoading(true);
        try {
            const response = await axios.post(process.env.REACT_APP_API_CONFIRM_EMAIL, {
                email: queryParams.get('email'),
                code: queryParams.get('code')
            });

            if (response.data.success) {
                if (response.data.url) {
                    setPlatformUrl(response.data.url);
                }
                nextStep(2);
            } else {
                setError('Email validation failed.');
            }
        } catch (e) {
            setError('An error occurred during email validation.');
        } finally {
            setLoading(false);
        }
    };

    const validateEmail = async (e) => {
        setLoading(true);
        try {
            const response = await axios.get(process.env.REACT_APP_API_VALIDATE_EMAIL + '?email=' + encodeURIComponent(queryParams.get('email')));
            if (response.data.valid) {
                nextStep(2);
            } else {
                if (e) {
                    setError('Email has not been verified');
                    setTimeout(() => {
                        setError('');
                    }, 3000);
                }
            }
        } catch (e) {
            setError('An error occurred during email validation.');
            setLoading(false);
        }
    };

    useEffect(() => {
        const email = queryParams.get('email');
        const code = queryParams.get('code');

        if (!formData.email && !email && !code) {
            previousStep(2);
        }

        if (email && code) {
            confirmEmail();
        }

        if (email) {
            const interval = setInterval(() => validateEmail(), 60000);
            return () => clearInterval(interval); // Clean up interval on unmount
        }

    }, [queryParams]);

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <p style={{ textAlign: 'center' }}>
                We've sent a confirmation email to <strong>{queryParams.get('email')}</strong>. Please open the email and click the verification link to confirm your address.
            </p>
            {loading && <Spinner />}
            {error && <p id='error_message' style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
            <p style={{ margin: '30px auto 0px auto' }}>
                Have you verified your email? <strong style={{ cursor: 'pointer' }} onClick={validateEmail}>Click here</strong>
            </p>
        </div>
    );
};

export default StepTwo;